import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, onMounted, ref, inject } from "vue";
import BasisGroup from "@/views/basisGroup/index.vue";
import { publicNewsByNewsTypeId, publicNewsListApi, publicNewsTypeApi, } from "@/api/news";
import Nav from "@/components/nav/nav.vue";
import router from "@/router/index";
import { useRoute } from "vue-router";
import HomeJumbotron from "@/views/home/homeJumbotron/index.vue";
export default defineComponent({
    components: {
        BasisGroup: BasisGroup,
        Nav: Nav,
        HomeJumbotron: HomeJumbotron
    },
    setup: function () {
        var _this = this;
        var route = useRoute();
        var coltd = inject("coltd");
        document.documentElement.scrollTop = 0;
        var newsTypeId = ref("");
        var newsList = ref("");
        var pageNum = ref(1);
        var pageSize = ref(5);
        var newsTypeList = ref("");
        var navActiveIndex = ref("4-1");
        //接收参数
        if (route.query.id) {
            newsTypeId.value = route.query.id;
        }
        // console.log("route.query.id:" + route.query.id);
        var JumpNewsDetail = function (item) {
            var newsInfo = JSON.stringify(item);
            // console.log("newsInfo:" + newsInfo)
            router.push({
                name: "newsDetail",
                query: { newsInfo: encodeURIComponent(newsInfo) },
            });
        };
        var getnewsTypeList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = newsTypeList;
                        return [4 /*yield*/, publicNewsTypeApi()];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        //分页查询新闻列表
        var getnewsList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        newsTypeId.value = "";
                        _a = newsList;
                        return [4 /*yield*/, publicNewsListApi({
                                currentPage: pageNum.value,
                                size: pageSize.value,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        //根据新闻类型获取新闻列表
        var getnewsListBytype = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = newsList;
                        return [4 /*yield*/, publicNewsByNewsTypeId({
                                current: pageNum.value,
                                newsTypeId: newsTypeId.value,
                                size: pageSize.value,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var selectNews = function (num) {
            pageNum.value = 1;
            newsTypeId.value = Number(num);
            getnewsListBytype();
        };
        var jumpPage = function (num) {
            pageNum.value = num;
            if (newsTypeId.value) {
                getnewsListBytype();
            }
            else {
                getnewsList();
            }
        };
        onMounted(function () {
            getnewsTypeList();
            if (newsTypeId.value) {
                getnewsListBytype();
            }
            else {
                getnewsList();
            }
        });
        return {
            JumpNewsDetail: JumpNewsDetail,
            getnewsList: getnewsList,
            newsList: newsList,
            pageNum: pageNum,
            pageSize: pageSize,
            navActiveIndex: navActiveIndex,
            newsTypeList: newsTypeList,
            selectNews: selectNews,
            jumpPage: jumpPage,
            coltd: coltd,
        };
    },
});
